
import { Component, Vue } from 'vue-property-decorator'
import StepThreeForm from '@/components/forms/StepThreeForm.vue'

@Component({
  components: {
    StepThreeForm
  }
})
export default class StepThree extends Vue {}
