import Vue from 'vue'
import Vuex from 'vuex'
import { createVuexStore, Module } from 'vuex-simple'
import VuexPersistence from 'vuex-persist'

import AuthModule from './modules/AuthModule'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage
})

export class RootModule {
  @Module()
  public auth: AuthModule = new AuthModule()
}

export default createVuexStore<RootModule>(new RootModule(), {
  plugins: [vuexLocal.plugin]
})
