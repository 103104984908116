import Vue from 'vue'
import VueRouter from 'vue-router'

import * as Guards from './guards'

import Home from '@/views/Home.vue'
import Contact from '@/views/Contact.vue'
import FAQ from '@/views/FAQ.vue'

import OptOutStepOne from '@/views/OptOut/StepOne.vue'
import OptOutStepTwo from '@/views/OptOut/StepTwo.vue'
import OptOutStepThree from '@/views/OptOut/StepThree.vue'
import OptOutStepFour from '@/views/OptOut/StepFour.vue'
import OptOutThankYou from '@/views/OptOut/ThankYou.vue'

import NotFound from '@/views/NotFound.vue'

Vue.use(VueRouter)

const DocumentTitle = 'First Orion Privacy'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },

  {
    path: '/opt-out/step-one',
    name: 'Opt Out Step One',
    component: OptOutStepOne,
    beforeEnter: Guards.canAccessStep
  },
  {
    path: '/opt-out/step-two',
    name: 'Opt Out Step Two',
    component: OptOutStepTwo,
    beforeEnter: Guards.canAccessStep
  },
  {
    path: '/opt-out/step-three',
    name: 'Opt Out Step Three',
    component: OptOutStepThree,
    beforeEnter: Guards.canAccessStep
  },
  {
    path: '/opt-out/step-four',
    name: 'Opt Out Step Four',
    component: OptOutStepFour,
    beforeEnter: Guards.canAccessStep
  },
  {
    path: '/opt-out/thank-you',
    name: 'Opt Out Thank You',
    component: OptOutThankYou,
    beforeEnter: Guards.canAccessStep
  },

  // Not Found Router
  {
    path: '/404',
    alias: '*',
    name: 'Not Found',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)

  if (to.name) {
    document.title = `${DocumentTitle} | ${to.name}`
  } else {
    document.title = DocumentTitle
  }

  next()
})

export default router
