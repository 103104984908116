import { State, Mutation, Action, Getter } from 'vuex-simple'
import jwt from 'jsonwebtoken'

import * as AuthAPI from '@/api/modules/auth'

export default class AuthModule {
  @State()
  public token: string | null = null

  @Mutation()
  public setToken (token: string | null): void {
    this.token = token
  }

  @Action()
  public async sendStepOneRequest (request: AuthAPI.StepOneRequest): Promise<void> {
    try {
      const response = await AuthAPI
        .sendRequest<AuthAPI.StepOneRequest, AuthAPI.StepOneResponse>(
          '/sendEmailVerification',
          request
        )

      this.setToken(response.jwt)
    } catch (e) {
      throw e
    }
  }

  @Action()
  public async sendStepTwoRequest (request: AuthAPI.StepTwoRequest): Promise<void> {
    try {
      const response = await AuthAPI
        .sendRequest<AuthAPI.StepTwoRequest, AuthAPI.StepTwoResponse>(
          '/verifyEmail',
          request
        )

      this.setToken(response.jwt)
    } catch (e) {
      throw e
    }
  }

  @Action()
  public async sendStepThreeRequest (request: AuthAPI.StepThreeRequest): Promise<void> {
    try {
      const response = await AuthAPI
        .sendRequest<AuthAPI.StepThreeRequest, AuthAPI.StepThreeResponse>(
          '/sendPhoneVerification',
          request
        )

      this.setToken(response.jwt)
    } catch (e) {
      throw e
    }
  }

  @Action()
  public async sendStepFourRequest (request: AuthAPI.StepFourRequest): Promise<void> {
    try {
      const response = await AuthAPI
        .sendRequest<AuthAPI.StepFourRequest, AuthAPI.StepFourResponse>(
          '/verifyPhone',
          request
        )

      this.setToken(response.jwt)
    } catch (e) {
      throw e
    }
  }

  @Getter()
  public get tokenData (): AuthAPI.TokenData | null {
    return this.token ? jwt.decode(this.token) as AuthAPI.TokenData : null
  }

  @Getter()
  public get authStep (): string {
    if (this.tokenData) {
      if (!this.tokenData.emailVerified) {
        return 'Opt Out Step Two'
      }

      if (!this.tokenData.phoneVerificationResource) {
        return 'Opt Out Step Three'
      }

      if (!this.tokenData.phoneVerified) {
        return 'Opt Out Step Four'
      }

      return 'Opt Out Thank You'
    } else {
      return 'Opt Out Step One'
    }
  }
}
