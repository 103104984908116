
import { Component, Vue } from 'vue-property-decorator'
import { useModule } from 'vuex-simple'
import VueElementLoading from 'vue-element-loading'

import { StepTwoRequest } from '@/api/modules/auth'
import AuthModule from '@/store/modules/AuthModule'

@Component({
  components: {
    VueElementLoading
  }
})
export default class StepTwoForm extends Vue {
  private authStore = useModule<AuthModule>(this.$store, ['auth'])
  private isLoading: boolean = false

  private emailCode: string | null = null

  private async onSubmit (): Promise<void> {
    if (!this.emailCode) {
      return this.$notify({
        title: 'Form Error',
        text: 'You must supply the Email Code',
        type: 'error'
      })
    }

    if (!this.authStore?.token) {
      return this.$notify({
        title: 'Form Error',
        text: 'You must have a token to perform this action',
        type: 'error'
      })
    }

    const request: StepTwoRequest = {
      jwt: this.authStore?.token,
      emailCode: this.emailCode
    }

    try {
      this.isLoading = true
      await this.authStore?.sendStepTwoRequest(request)
      this.$router.push({ name: 'Opt Out Step Three' })
    } catch (e) {
      this.$notify({
        title: 'Form Error',
        text: e.message,
        type: 'error'
      })
    } finally {
      this.isLoading = false
    }
  }

  private onRestart (): void {
    sessionStorage.clear()
    this.$router.push({
      name: 'Opt Out Step One'
    })
    location.reload()
  }

  private get canSubmit (): boolean {
    return Boolean(this.emailCode)
  }
}
