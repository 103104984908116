
import { Component, Vue } from 'vue-property-decorator'
import { useModule } from 'vuex-simple'
import VueElementLoading from 'vue-element-loading'

import { StepThreeRequest } from '@/api/modules/auth'
import AuthModule from '@/store/modules/AuthModule'

@Component({
  components: {
    VueElementLoading
  }
})
export default class StepThreeForm extends Vue {
  private authStore = useModule<AuthModule>(this.$store, ['auth'])
  private isLoading: boolean = false

  private async onSubmit (type: 'sms' | 'call') {
    if (!this.authStore?.token) {
      return
    }

    const request: StepThreeRequest = {
      jwt: this.authStore?.token,
      phoneVerificationType: type
    }

    try {
      this.isLoading = true
      await this.authStore?.sendStepThreeRequest(request)
      this.$router.push({ name: 'Opt Out Step Four' })
    } catch (e) {
      this.$notify({
        title: 'Form Error',
        text: e.message,
        type: 'error'
      })
    } finally {
      this.isLoading = false
    }
  }

  private onRestart (): void {
    sessionStorage.clear()
    this.$router.push({
      name: 'Opt Out Step One'
    })
    location.reload()
  }
}
