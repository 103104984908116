
import { Component, Vue } from 'vue-property-decorator'
import { useModule } from 'vuex-simple'
import VueRecaptcha from 'vue-recaptcha'
import VueElementLoading from 'vue-element-loading'
import { TheMask } from 'vue-the-mask'

import { StepOneRequest } from '@/api/modules/auth'
import AuthModule from '@/store/modules/AuthModule'
import config from '@/config'

@Component({
  components: {
    VueRecaptcha,
    VueElementLoading,
    TheMask
  }
})
export default class StepOneForm extends Vue {
  private authStore = useModule<AuthModule>(this.$store, ['auth'])
  private isLoading: boolean = false

  private firstName: string | null = null
  private lastName: string | null = null
  private email: string | null = null
  private phone: string | null = null
  private consented: boolean = false
  private submissionType: 'opt-out' | 'data' = 'opt-out'
  private recaptchaCode: string | null = null

  private async onSubmit () {
    if (!this.firstName) {
      return this.$notify({
        title: 'Form Error',
        text: 'You must supply your first name',
        type: 'error'
      })
    }

    if (!this.lastName) {
      return this.$notify({
        title: 'Form Error',
        text: 'You must supply your last name',
        type: 'error'
      })
    }

    if (!this.email) {
      return this.$notify({
        title: 'Form Error',
        text: 'You must supply your email',
        type: 'error'
      })
    }

    if (!this.phone || (this.phone && this.phone.length !== 10)) {
      return this.$notify({
        title: 'Form Error',
        text: 'You must supply a valid phone number',
        type: 'error'
      })
    }

    if (!this.consented) {
      return this.$notify({
        title: 'Form Error',
        text: 'You must check that you are legally allowed to opt-out for the provided information',
        type: 'error'
      })
    }

    if (!this.recaptchaCode) {
      return this.$notify({
        title: 'Form Error',
        text: 'You must check the recaptcha code before proceeding',
        type: 'error'
      })
    }

    const request: StepOneRequest = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      consented: this.consented,
      submissionType: this.submissionType,
      recaptchaCode: this.recaptchaCode
    }

    try {
      this.isLoading = true
      await this.authStore?.sendStepOneRequest(request)
      this.$router.push({ name: 'Opt Out Step Two' })
    } catch (e) {
      console.log(e)
      this.$notify({
        title: 'Form Error',
        text: e.message,
        type: 'error'
      })
    } finally {
      this.isLoading = false
    }
  }

  private onRecaptchaChange (code: string | null = null) {
    this.recaptchaCode = code
  }

  private get recaptchaSiteKey (): string {
    return config.RECAPTCHA_SITE_KEY
  }

  private get canSubmit (): boolean {
    return Boolean(this.firstName && this.lastName && this.email && this.phone && this.consented && this.recaptchaCode)
  }
}
