
import { Component, Vue } from 'vue-property-decorator'
import StepFourForm from '@/components/forms/StepFourForm.vue'

@Component({
  components: {
    StepFourForm
  }
})
export default class StepTwo extends Vue {}
