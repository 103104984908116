
import { Component, Vue } from 'vue-property-decorator'
import StepOneForm from '@/components/forms/StepOneForm.vue'

@Component({
  components: {
    StepOneForm
  }
})
export default class StepOne extends Vue {}
