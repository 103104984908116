var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"home"}},[_c('header',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-3 col-xs-3"}),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 col-xs-6 pt-5"},[_vm._m(0),_vm._m(1),_c('p',[_c('router-link',{staticClass:"btn btn-primary btn-large p-3",attrs:{"to":{ name: 'FAQ' }}},[_vm._v(" FAQs ")])],1)]),_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-3 col-xs-3"})])])]),_c('div',{staticClass:"container-fluid"},[_vm._m(2),_c('div',{staticClass:"row orion-apps"},[_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-3 col-xs-3"}),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-6 col-xs-6 pt-5"},[_vm._m(3),_c('p',{staticClass:"pt-4 text-center"},[_vm._v(" In order to opt-out (request that First Orion not sell your personal information to third parties) and have your personal information deleted from our Call Knowledge Suite of products, you will need to have access to the device associated with the phone number you are opting out and a valid email address to receive communication. ")]),_c('p',{staticClass:"text-center"},[_vm._v(" Your phone number and email address will be verified as part of the request process for security purposes. ")]),_c('p',{staticClass:"pt-4 pb-4 text-center"},[_c('router-link',{staticClass:"btn btn-primary btn-large p-3",attrs:{"to":{ name: 'Opt Out Step One' }}},[_vm._v(" GET STARTED ONLINE ")])],1),_vm._m(4),_vm._m(5),_c('p',{staticClass:"text-center"},[_vm._v(" You may also initiate the opt-out process by calling 1-877-640-4220 (toll-free). ")])]),_c('div',{staticClass:"col-lg-3 col-md-3 col-sm-3 col-xs-3"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h3',{staticClass:"pt-5"},[_c('strong',[_vm._v("Trust")]),_vm._v(" your phone again. ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v(" First Orion’s mission is to provide transparency in communication that empowers people to trust their phones again. "),_c('br'),_vm._v(" Within our Call Knowledge Suite, we provide information to businesses to help them provide accurate caller identification, identify suspected fraudulent calls, offer verification of name, address and phone, and comply with various telecommunication laws. ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row sub-header p-5"},[_c('div',{staticClass:"col-lg-2 col-md-2 col-sm-2 col-xs-2"}),_c('div',{staticClass:"col-lg-8 col-md-8 col-sm-8 col-xs-8"},[_c('h4',{staticClass:"my-4"},[_vm._v(" The personal information we sell is limited to phone number, name, address, current carrier, and line type (i.e., wireless, landline, VoIP). "),_c('br'),_vm._v("If you do not wish First Orion to sell this information about you within our Call Knowledge Suite, you may opt-out below. ")]),_c('h4',{staticClass:"my-4"},[_vm._v(" For more information about our products and our privacy practices, please see "),_c('a',{attrs:{"href":"https://firstorion.com/privacy-policy"}},[_vm._v(" First Orion’s Privacy Policy ")])])]),_c('div',{staticClass:"col-lg-2 col-md-2 col-sm-2 col-xs-2"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h2',{staticClass:"my-2"},[_vm._v("Do you want to"),_c('br'),_vm._v("Opt-Out?")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"text-center"},[_vm._v(" If you do not have an email address, you may opt-out via postal mail "),_c('a',{attrs:{"href":"/files/FOIndividualForm.pdf"}},[_vm._v("here")]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"text-center"},[_vm._v(" If you are an Agent representing an individual, "),_c('a',{attrs:{"href":"/files/FOAgentForm.pdf"}},[_vm._v("click here")]),_vm._v(" to contact us. ")])
}]

export { render, staticRenderFns }