
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class TheNavbar extends Vue {
  private show: boolean = false

  private toggleNavbar (): void {
    this.show = !this.show
  }
}
