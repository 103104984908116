
import { Component, Vue } from 'vue-property-decorator'
import StepTwoForm from '@/components/forms/StepTwoForm.vue'

@Component({
  components: {
    StepTwoForm
  }
})
export default class StepTwo extends Vue {}
