
import { Component, Vue } from 'vue-property-decorator'
import { useModule } from 'vuex-simple'
import moment from 'moment'

import AuthModule from '@/store/modules/AuthModule'

@Component({
  filters: {
    toDateTime (value: Date) {
      return moment(value).format('LLL')
    }
  }
})
export default class ThankYou extends Vue {
  private authStore = useModule<AuthModule>(this.$store, ['auth'])
  private submissionType: 'opt-out' | 'data' = 'opt-out'
  private submissionId: string | null = null
  private date: Date = new Date()

  private mounted () {
    this.submissionType = this.authStore?.tokenData?.submissionType || 'opt-out'
    this.submissionId = this.authStore?.tokenData?.submissionId || null
    this.authStore?.setToken(null)
  }

  private onReset () {
    this.authStore?.setToken(null)
    this.$router.push({ name: 'Opt Out Step One' })
  }
}
