import { NavigationGuard } from 'vue-router'
import { useModule } from 'vuex-simple'

import store from '@/store'
import AuthModule from '@/store/modules/AuthModule'

export const canAccessStep: NavigationGuard = function (to, from, next) {
  const authStore = useModule<AuthModule>(store, ['auth'])
  const authStep: string | undefined = authStore?.authStep

  if (authStep) {
    if (to.name === authStep) {
      next()
    } else {
      next({
        name: authStep
      })
    }
  } else {
    next()
  }
}
