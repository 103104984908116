import API from '../index'
import Axios, { AxiosError } from 'axios'

export type TokenData = {
  firstName: string
  lastName: string
  email: string
  phone: string
  consented: boolean
  submissionType: 'opt-out' | 'data'
  emailVerified?: boolean
  phoneVerificationResource?: string
  phoneVerified?: boolean
  submissionId?: string
}

export class BadRequestError extends Error {}
export class UnauthorizedError extends Error {}

export type StepOneRequest = {
  firstName: string
  lastName: string
  email: string
  phone: string
  consented: boolean
  submissionType: 'opt-out' | 'data'
  recaptchaCode: string
}

export type StepOneResponse = {
  jwt: string
}

export type StepTwoRequest = {
  jwt: string
  emailCode: string
}

export type StepTwoResponse = {
  jwt: string
}

export type StepThreeRequest = {
  jwt: string
  phoneVerificationType: 'sms' | 'call'
}

export type StepThreeResponse = {
  jwt: string
}

export type StepFourRequest = {
  jwt: string
  phoneCode: string
}

export type StepFourResponse = {
  jwt: string
}

export function isAxiosError (error: any): error is AxiosError {
  return error?.isAxiosError
}

export async function sendRequest<R, T> (url: string, request: R): Promise<T> {
  try {
    return (await API.post<T>(url, request)).data
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response?.status === 400) {
        throw new BadRequestError(e.response?.data?.message)
      } else if (e.response?.status === 401) {
        throw new UnauthorizedError()
      }
    }

    throw e
  }
}
