
import { Component, Vue } from 'vue-property-decorator'

import TheNavbar from '@/components/navigation/TheNavbar.vue'
import TheFooter from '@/components/navigation/TheFooter.vue'

@Component({
  components: {
    TheNavbar,
    TheFooter
  }
})
export default class App extends Vue {}
